.form-control {
  margin: 0 auto;
  height: 80px;
}

.form-control label,
.form-control input,
.form-control textarea,
.form-control select {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  color: #06f97f;
}

.form-control input,
.form-control textarea,
.form-control select {
  border-radius: 6px;
  height: 40px;
  width: 100%;
  font: inherit;
  border: 3px solid #06f97f;
  background: #ffffff;
  padding: 0.15rem 0.25rem;
}

.form-control textarea {
  max-width: 100%;
  max-height: 120px;
  min-height: 80px;
}

.form-control input:focus,
.form-control textarea:focus,
.form-control select:focus {
  outline: none;
  background: #ffffff;
}

.form-control--invalid label,
.form-control--invalid p {
  color: #960101;
}

.form-control--invalid p {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
}

.form-control--invalid input,
.form-control--invalid textarea,
.form-control--invalid select {
  border-color: #960101;
  background: #ffffff;
}
