.auth {
  width: 100vw;
  height: 100vh;
  background-color: #e7eaee;
  padding-top: 3rem;
  margin: 0;
  max-width: none;
}

.logo__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.logo__container img {
  width: 250px;
  margin-right: 25px;
}

.auth__container {
  padding: 1.5rem 1.5rem;
  width: 95%;
  max-width: 350px;
  margin: 0 auto;
  background: #202945;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  position: relative;
}

.auth__inputs {
  min-height: 250px;
}

.auth__input-container {
  min-height: 70px;
  margin-bottom: 10px;
}

.auth--error {
  margin-bottom: 1.5rem;
}

.auth--error p {
  margin: 0;
  font-weight: 600;
  text-align: center;
  color: #ca1313;
}
