.footer {
    height: 50px;
    width: 100%;
    background: #ffffff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer__text-container {
    color: black;
}

.footer__button-container {
    position: absolute;
    right: 1rem;
    display: inline-block;
}

.footer__button-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__button-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.footer__button-icon img {
    width: 100%;
}

.footer__button-items p {
    margin: 0;
    margin-right: 10px;
}
