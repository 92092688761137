.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.55);
    z-index: 10;
}

.backdrop--content {
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}